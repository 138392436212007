import React from "react"
import { Grid, Box, Text, Image } from "theme-ui"
import Time from "../Time"

export default function Preface({ title, timeDescription, timeLength, src, children1, children2, version, design, art }) {
  return (
    <>
      <Grid variant="base">
        <Image src={src} alt={title} sx={{ maxWidth: "80%", mx: "auto", mb: 3 }} />
          
        <Box sx={{ mb: [6, 8], textAlign: "center" }}>
          <Text as="h1" variant="display">{title}</Text>
          <Time description={timeDescription} time={timeLength} />
        </Box>
      </Grid>

      <Grid variant="base" gap={[4, 6, 8]} columns={[1, "2fr 1fr"]} sx={{ alignItems: "start", mb: [5, 6] }}>
        <Box>
          {children1}
        </Box>
          
        <Box sx={{ borderTop: "1px solid", borderBottom: "1px solid", borderColor: "grayMedium", color: "grayDark", p: 1, pt: 2 }}>
          <Text as="p" variant="label">Ver. {version}</Text>
          <Text as="p" variant="label">Design: {design}</Text>
          <Text as="p" variant="label">Art: {art}</Text>
        </Box>
      </Grid>
      
      {children2}
    </>
  )
}
