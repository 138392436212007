import React from "react"
import { Link } from "gatsby"
import { Grid, Box, Flex, Text, Button } from "theme-ui"

export default function SubNav({ title, parts }) {
  return (
    <Grid
      variant="base"
      id="top"
      sx={{
        mt: [6, 8],
        py: 2,
        bg: "text",
        color: "background",
        gridGap: 0,
        position: ["static", "sticky"],
        top: 0,
        zIndex: 3
      }}
    >
      <Flex sx={{ flexWrap: "wrap", alignItems: "center", mx: -1 }}>
        <Text as="p" variant="label" sx={{ mt: "2px", mb: 0, pr: 2, mr:  2, borderRight: "1px solid" }}>{title}</Text>
        
        {parts.map((navItem) => (
          <Box sx={{ mr: 2 }}>
            <Link key={navItem} to={`#${navItem[1].replace(/ /g,'-').toLowerCase()}`}>
              <Button variant="tertiary" sx={{ bg: "text", color: "background", "&:hover": { bg: "grayXDark" }, "&:focus": { bg: "grayXDark" } }}>{navItem[0] === 0 ? "Introduction" : `Part ${navItem[0]}`}</Button>
            </Link>
          </Box>
        ))}
      </Flex>
    </Grid>
  )
}
