import React from "react"
import { Link } from "gatsby"
import { Text } from "theme-ui"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import Preface from "../../../components/adventures/short-stories/Preface"
import Section from "../../../components/adventures/Section"
import Block from "../../../components/adventures/Block"
import SubNav from "../../../components/adventures/short-stories/SubNav"
import PageNumber from "../../../components/adventures/PageNumber"
import Intro from "./intro"
import Part01 from "./part01"
import Part02 from "./part02"
import Part03 from "./part03"
import Part04 from "./part04"
import Part05 from "./part05"
import Part06 from "./part06"
import Conclusion from "./conclusion"

import mischiefMountainImage from '../../../images/spots/mischief-mountain.png'

const MischiefMountainPage = () => (
  <Layout noButton>
    <SEO title="Adventures" />

    <Preface
      title="Mischief Mountain"
      timeDescription="Short Story"
      timeLength=" 3–4 hours"
      src={mischiefMountainImage}
      version="1.0"
      design="TC Sottek"
      art="Grim Wilkins"
      children1={
        <>
          <Text as="p" variant="body">This is a short adventure for Quest. It is designed to be completed in a single play session, but can also be used to begin a longer adventure. This is a good adventure for beginners, but it can be used for players with any level of experience.</Text>
          <Text as="h3" variant="title03" sx={{ mt: 3 }}>Spoiler Warning</Text>
          <Text as="p" variant="body">This manual is meant to be used by The Guide to help narrate the story. If you are playing as one of the story’s main characters, don’t read further — you may spoil the game for yourself and your friends.</Text>
          <Text as="h3" variant="title03" sx={{ mt: 3 }}>How to Use This Manual</Text>
          <Text as="p" variant="body">Check out <Link to="/adventures/how-to"><Text as="span" variant="link">this guide</Text></Link> to learn how to read this manual. </Text>
          <Text as="h3" variant="title03" sx={{ mt: 3 }}>Setup</Text>
          <Text as="p" variant="body">The <Link to="/store/quest-game-book"><Text as="span" variant="link">Quest Game Book</Text></Link> is required to play this adventure. You can use the standard character creation process to help players create their characters. If your group decides to create characters during the play session, you should expect to add up to an hour to the session for setup. You can reduce this setup time by having the players create their characters before you meet.</Text>
          <Text as="p" variant="body">As The Guide, you should read this manual before meeting to play. Reading this page in advance will help you run the adventure more smoothly.</Text>
        </>
      }
      children2={
        <>
          <Section type="Context">
            <Block type="Secret Context" title="Premise">
              <Text>Mischief Mountain is a mysterious place that is designed to trap adventurers and tease them with challenges. It was created by The Trickster: a mischievous Magician who collects loot from around the omniverse to hoard in Treasure Town, which sits at the base of the mountain. The Trickster sits in their mountaintop tower, watching the adventurers who arrive for entertainment.</Text>
              <Text>The realm of Mischief Mountain is hidden in a <PageNumber digital="33" physical="52">shadow plane</PageNumber>. The Trickster transports adventurers to the plane by luring them with a magic treasure map. The map leads them to a foggy forest that acts as a portal to The Trickster's domain. When the players enter the fog, they are transported to the shadow plane and are trapped until The Trickster lets them go.</Text>
              <Text>If you want to run Mischief Mountain as a lighthearted adventure, you can make it an illusory dreamworld where the adventurers cannot die or be harmed. If they were to die, they can wake up in Treasure Town and try the adventure again to see if they can escape The Trickster's trap.</Text>
              <Text>For a more serious adventure, you can have the players be threatened with real dangers. The Trickster can be a playful foe, or a cruel and deadly villain.</Text>
              <Text>When the players arrive at Treasure Town, they will be allowed to pick one magic item from The Trickster's treasure hoard. If they successfully make it to the top of mountain and defeat The Trickster, they will be set free and allowed to keep their treasure.</Text>
            </Block>
          </Section>
        </>
      }
    />

    <SubNav
      title="Mischief Mountain"
      parts={[
        [0, "Introduction"],
        [1, "Treasure Town"],
        [2, "The Gregarious Gate"],
        [3, "The Cavernous Caves"],
        [4, "The Coniferous Climb"],
        [5, "The Golden Guardian"],
        [6, "The Trickster's Tower"]
      ]} />

    <Intro />
    <Part01 />
    <Part02 />
    <Part03 />
    <Part04 />
    <Part05 />
    <Part06 />
    <Conclusion />
  </Layout>
)

export default MischiefMountainPage